import { createMemo, createSignal, Show } from "solid-js";
import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import { gotoUrlWithUtm } from "~/utils/url";
import { getFormEventNameFromCrmObject } from "./ProgramFormInPage";

import type { ProgramWrapper } from "~/utils/program_wrapper";
import type { ParagraphPush } from "~/types/drupal_jsonapi";

import "./ParagraphProgramPush.css";
import { hasNoEscape } from "~/utils/no_escape";
import IconExpandMore from "~/img/icons/expand_more.svg";

type ParagraphPushProps = {
  wrapper: ProgramWrapper;
  push: ParagraphPush;
};

export default function ParagraphProgramPush(props: ParagraphPushProps) {
  const [, { sendClick }] = useEventsContext();
  const [, { sendShowEvent }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();

  const [descriptionIsVisible, setDescriptionIsVisible] = createSignal(false);

  const remainingDaysCount = createMemo(() => {
    if (!props.push.field_countdown_stop) {
      return 0;
    }

    const now = new Date();
    const date = new Date(props.push.field_countdown_stop);

    if (!date) {
      return 0;
    }

    const diff = date.getTime() - now.getTime();
    const days = Math.floor(diff / (60 * 60 * 24 * 1000));
    return days > 0 ? days : 0;
  });

  return (
    <>
      <Show when={props.push.field_disp}>
        <div
          class="paragraph-push"
          classList={{ "no-escape": hasNoEscape() }}
          style={{
            background: props.push.field_bg_color ?? props.push.field_bg_color,
            color: props.push.field_text_color ?? props.push.field_text_color,
          }}
        >
          <div class="push-text" classList={{ opened: descriptionIsVisible() }}>
            <div class="the-text" data-test="body">
              <Show when={props.push.field_title}>
                <h3
                  classList={{
                    "with-description":
                      props.push.field_description !== undefined,
                  }}
                >
                  <Show
                    when={
                      props.push.field_countdown_disp &&
                      remainingDaysCount() > 0
                    }
                  >
                    <span class="push-count" data-test="countdown">
                      <span>J-{remainingDaysCount()}</span>
                    </span>
                  </Show>
                  {props.push.field_title}
                  <Show when={props.push.field_description}>
                    <i
                      aria-hidden="true"
                      class="cog-icon"
                      onClick={() =>
                        setDescriptionIsVisible(!descriptionIsVisible())
                      }
                    >
                      <IconExpandMore />
                    </i>
                  </Show>
                </h3>
              </Show>
              <Show when={props.push.field_description}>
                <p>{props.push.field_description}</p>
              </Show>
            </div>
            <Show when={props.push.field_button_text}>
              <Show
                fallback={
                  <button
                    type="button"
                    class="btn"
                    data-test="btn"
                    onClick={() => {
                      sendClick("btn-push-program", "first-screen");
                      gotoUrlWithUtm(props.push.field_button_url!);
                    }}
                  >
                    {props.push.field_button_text}
                  </button>
                }
                when={props.push.field_form_disp}
              >
                <button
                  type="button"
                  class="btn"
                  data-test="cta-in-page"
                  onClick={(e) => {
                    sendShowEvent(
                      getFormEventNameFromCrmObject(
                        props.wrapper.program.field_form.field_crm_object,
                      ),
                      e,
                      {
                        nid: props.wrapper.program.drupal_internal__nid,
                      },
                    );
                    openModalForm!("in-page", {
                      wrapper: props.wrapper,
                      formConfig: props.wrapper.program.field_form,
                    });
                    setModalFormHeadline!(
                      props.wrapper.program.field_form.field_title,
                    );
                  }}
                >
                  {props.push.field_button_text}
                </button>
              </Show>
            </Show>
          </div>
        </div>
      </Show>
    </>
  );
}
