import { Show } from "solid-js";
import * as helper from "~/utils/helper_program";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { useEventsContext } from "~/contexts/EventsContext";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import IconPlayCircleOutline from "~/img/icons/play_circle_outline.svg";
import Icon3dRotation from "~/img/icons/3d_rotation.svg";
import IconPhotoLibraryOutline from "~/img/icons/photo_library_outline.svg";
import IconLocationOnOutline from "~/img/icons/location_on_outline.svg";

type MediaContainerProps = {
  wrapper: ProgramWrapper;
};

export default function MediaContainer(props: MediaContainerProps) {
  const settingsContext = useDrupalSettingsContext();
  const [, { setMediaOverlay }] = useMediaOverlayContext();
  const [, { sendClick }] = useEventsContext();

  return (
    <>
      <div class="media-container">
        <Show when={props.wrapper.program.field_video.length > 0}>
          <div class="media-button" data-test="btn-video">
            <button
              class="btn btn-icon"
              type="button"
              aria-label={
                props.wrapper.program.field_video.length > 1
                  ? "Vidéos"
                  : "Vidéo"
              }
              onClick={() => {
                sendClick("btn-videos", "first-screen");
                setMediaOverlay("video", props.wrapper.program.field_video);
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPlayCircleOutline />
              </i>
            </button>
            <Show
              fallback={<span class="tip">Vidéo</span>}
              when={props.wrapper.program.field_video.length > 1}
            >
              <span class="count">
                {props.wrapper.program.field_video.length}
              </span>
              <span class="tip">Vidéos</span>
            </Show>
          </div>
        </Show>
        <Show when={helper.virtualVisitsLength(props.wrapper) > 0}>
          <div class="media-button" data-test="btn-3d">
            <button
              class="btn btn-icon"
              type="button"
              aria-label="3D"
              onClick={() => {
                sendClick("btn-virtual-tour", "first-screen");
                setMediaOverlay("3ds", props.wrapper.program);
              }}
            >
              <i aria-hidden="true" class="cog-icon threed">
                <Icon3dRotation />
              </i>
            </button>
            <Show
              fallback={<span class="tip">3D</span>}
              when={helper.virtualVisitsLength(props.wrapper) > 1}
            >
              <span class="count">
                {helper.virtualVisitsLength(props.wrapper)}
              </span>
              <span class="tip">3D</span>
            </Show>
          </div>
        </Show>
        <Show
          when={
            props.wrapper.program.field_image_desktop &&
            props.wrapper.program.field_image_desktop!.length > 0
          }
        >
          <div class="media-button" data-test="btn-gallery">
            <button
              class="btn btn-icon"
              type="button"
              aria-label="Galerie"
              onClick={() => {
                sendClick("btn-gallery", "first-screen");
                setMediaOverlay(
                  "images",
                  props.wrapper.program.field_image_desktop,
                );
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPhotoLibraryOutline />
              </i>
            </button>
            <span class="count">
              {props.wrapper.program.field_image_desktop!.length}
            </span>
            <span class="tip">Galerie</span>
          </div>
        </Show>
        <Show
          when={
            !props.wrapper.program.field_address_hide &&
            settingsContext.cityscan_is_enabled
          }
        >
          <div class="media-button" data-test="btn-map">
            <button
              class="btn btn-icon"
              type="button"
              aria-label="Carte"
              onClick={() => {
                sendClick("btn-map", "first-screen");
                setMediaOverlay(
                  "embed",
                  `<iframe height="100%" width="100%" src="https://www.cityscan.fr/widget?clientKey=${settingsContext.cityscan_client_key}&extAddressId=${props.wrapper.program.field_program_id}&minZoom=11&landingZoom=${props.wrapper.program.field_scale}&maxZoom=18" frameborder="0" allowfullscreen></iframe>`,
                );
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconLocationOnOutline />
              </i>
            </button>
            <span class="tip">Carte</span>
          </div>
        </Show>
      </div>
    </>
  );
}
