import { createSignal, Show, Suspense } from "solid-js";
import { clientOnly } from "@solidjs/start";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import ProgramsNearbyList from "~/components/Program/Components/ProgramsNearbyList";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import "./ProgramsNearby.css";
import { ProjectErrorBoundary } from "~/components/shared/ProjectErrorBoundary";

const ProgramsNearbyMap = clientOnly(() => import("./ProgramsNearbyMap"));

type ProgramsNearbyProps = {
  wrapper: ProgramWrapper;
};

export default function ProgramsNearby(props: ProgramsNearbyProps) {
  const settingsContext = useDrupalSettingsContext();

  const [isDisplayingList, setIsDisplayingList] = createSignal(true);

  return (
    <>
      <section
        class="programs-nearby"
        data-ga-zone="other-programs"
        data-test="programs-nearby"
      >
        <div class="content-part">
          <Show
            fallback={
              <h2 class="h2">
                <strong>Nos autres résidences</strong> à proximité
              </h2>
            }
            when={
              props.wrapper.program.field_program_type?.drupal_internal__tid ===
              settingsContext.program_types.terrains
            }
          >
            <h2 class="h2">
              <strong>Nos autres terrains</strong> à proximité
            </h2>
          </Show>

          <div class="switch">
            <button
              type="button"
              class="btn"
              classList={{ active: isDisplayingList() }}
              onClick={() => setIsDisplayingList(true)}
            >
              Liste
            </button>
            <button
              type="button"
              class="btn"
              classList={{ active: !isDisplayingList() }}
              onClick={() => setIsDisplayingList(false)}
            >
              Carte
            </button>
          </div>
        </div>

        <div class="programs-list" data-ga-zone="list">
          {/* List */}
          <Show when={isDisplayingList()}>
            <ProjectErrorBoundary>
              <ProgramsNearbyList
                nearbyPrograms={props.wrapper.program.other_programs_nearby}
              />
            </ProjectErrorBoundary>
          </Show>

          {/* Map */}
          <Show when={!isDisplayingList()}>
            <Suspense>
              <ProgramsNearbyMap
                currentProgram={props.wrapper.program}
                nearbyPrograms={props.wrapper.program.other_programs_nearby}
              />
            </Suspense>
          </Show>
        </div>
      </section>
    </>
  );
}
