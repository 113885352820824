import { createSignal, onMount, Show } from "solid-js";
import { urlRs } from "~/utils/url";

import IconKeyboardArrowLeft from "~/img/icons/keyboard_arrow_left.svg";
import IconKeyboardArrowRight from "~/img/icons/keyboard_arrow_right.svg";
import IconCheck from "~/img/icons/check.svg";

import IconService01b from "~/img/services/service-01b.svg";
import IconService02b from "~/img/services/service-02b.svg";
import IconService03b from "~/img/services/service-03b.svg";
import IconService05 from "~/img/services/service-05.svg";
import IconService06 from "~/img/services/service-06.svg";
import IconService07 from "~/img/services/service-07.svg";
import IconService08 from "~/img/services/service-08.svg";
import IconService09 from "~/img/services/service-09.svg";
import IconService10 from "~/img/services/service-10.svg";

import "./Tailored.css";
import { useEventsContext } from "~/contexts/EventsContext";

export default function Tailored(props: {
  onlyFinance: boolean;
  brandId: string;
}) {
  const [currentTabActive, setCurrentTabActive] = createSignal(1);
  const [, { sendClick }] = useEventsContext();

  onMount(() => {
    if (props.brandId !== "0" || props.onlyFinance) {
      setCurrentTabActive(2);
    }
  });

  return (
    <>
      <section
        class="tailored-block"
        data-ga-zone="services"
        data-test="block-services"
      >
        <div class="content-part">
          <h2>
            <Show
              when={props.brandId !== "0"}
              fallback={
                <>
                  Découvrez <strong>les services Cogedim</strong>&nbsp;!
                </>
              }
            >
              <>
                Découvrez <strong>nos services</strong>
              </>
            </Show>
          </h2>
          <div class="switch" data-test="switch">
            <Show
              fallback={
                <button
                  type="button"
                  class="btn"
                  classList={{ active: currentTabActive() === 2 }}
                  data-test="finance"
                  onClick={() => sendClick("tab-services-2", "services")}
                >
                  Financement
                </button>
              }
              when={!props.onlyFinance}
            >
              <Show when={props.brandId === "0"}>
                <button
                  type="button"
                  class="btn"
                  classList={{ active: currentTabActive() === 1 }}
                  onClick={() => {
                    sendClick("tab-services-1", "services");
                    setCurrentTabActive(1);
                  }}
                  data-test="personalization"
                >
                  Personnalisation
                </button>
              </Show>
              <button
                type="button"
                class="btn"
                classList={{ active: currentTabActive() === 2 }}
                onClick={() => {
                  sendClick("tab-services-2", "services");
                  setCurrentTabActive(2);
                }}
                data-test="finance"
              >
                Financement
              </button>
              <button
                type="button"
                class="btn"
                classList={{ active: currentTabActive() === 3 }}
                onClick={() => {
                  sendClick("tab-services-3", "services");
                  setCurrentTabActive(3);
                }}
                data-test="rental"
              >
                Gestion locative
              </button>
            </Show>
          </div>

          {/* Personnalisation */}
          <Show when={!props.onlyFinance && props.brandId === "0"}>
            <div
              class="slide"
              classList={{ active: currentTabActive() === 1 }}
              data-test="tab-personalization"
            >
              <TailoredSlide1 />
            </div>
          </Show>
          {/* Financement */}
          <div
            class="slide"
            classList={{ active: currentTabActive() === 2 }}
            data-test="tab-finance"
          >
            <TailoredSlide2 />
          </div>
          {/* Garantie locative */}
          <Show when={!props.onlyFinance}>
            <div
              class="slide"
              classList={{ active: currentTabActive() === 3 }}
              data-test="tab-rental"
            >
              <TailoredSlide3 />
            </div>
          </Show>
        </div>
      </section>
    </>
  );
}

function TailoredSlide1() {
  const [, { sendClick }] = useEventsContext();

  const [itemActive, setItemActive] = createSignal(1);

  return (
    <>
      <p class="catchline">
        Choisir un logement Cogedim, c’est opter pour un logement à votre image,
        adapté à votre mode de vie et à vos envies. Découvrez vite toutes les
        options disponibles pour votre projet avec nos 3 packs de
        personnalisation clé en main !
      </p>
      <div class="services-slider customization">
        <div
          class="inner-slider"
          classList={{
            "step-1": itemActive() === 1,
            "step-2": itemActive() === 2,
            "step-3": itemActive() === 3,
          }}
          data-test="cards"
        >
          <div class="program-service">
            <div
              class="service"
              classList={{ active: itemActive() === 1 }}
              data-test="card"
            >
              <h3>Le Pack Essentiel</h3>
              <span class="icon-service">
                <IconService01b />
              </span>
              <p>
                Votre cuisine aménagée Essentiel installée avant livraison.
                <br />
                Des prestations complémentaires disponibles.
                <br />6 mois de gestion locative offerts* pour les
                investisseurs.
              </p>
            </div>
          </div>
          <div class="program-service">
            <div
              class="service"
              classList={{ active: itemActive() === 2 }}
              data-test="card"
            >
              <h3>Le Pack Premium</h3>
              <span class="icon-service">
                <IconService02b />
              </span>
              <p>
                Votre cuisine aménagée Premium installée avant livraison.
                <br />
                Des prestations complémentaires disponibles.
                <br />6 mois de gestion locative offerts* pour les
                investisseurs.
              </p>
            </div>
          </div>
          <div class="program-service">
            <div
              class="service"
              classList={{ active: itemActive() === 3 }}
              data-test="card"
            >
              <h3>Le Pack Sécurité</h3>
              <span class="icon-service">
                <IconService03b />
              </span>
              <p>
                Faites le choix d’un logement sécurisé avec le système de
                sécurité intelligent de NexHome et bénéficiez de 12 mois de
                télésurveillance compris !
              </p>
            </div>
          </div>
        </div>
        <i
          aria-hidden="true"
          class="cog-icon nav-arrow previous"
          onClick={() => {
            if (itemActive() > 1) {
              setItemActive(itemActive() - 1);
            }
          }}
          classList={{ disabled: itemActive() === 1 }}
          data-test="btn-previous"
        >
          <IconKeyboardArrowLeft />
        </i>
        <i
          aria-hidden="true"
          class="cog-icon nav-arrow next"
          onClick={() => {
            if (itemActive() < 3) {
              setItemActive(itemActive() + 1);
            }
          }}
          classList={{ disabled: itemActive() === 3 }}
          data-test="btn-next"
        >
          <IconKeyboardArrowRight />
        </i>
      </div>
      <a
        href="/pdf/CATALOGUE-PARTIEL-PACKS-2024-Ess-Prem-Secu.pdf"
        class="btn"
        data-test="btn-catalog"
        target="_blank"
        onClick={() => {
          sendClick("btn-catalog-services", "services");
        }}
      >
        Télécharger le catalogue
      </a>
      <p class="legals" data-test="legals">
        * 6 mois d'honoraires de gestion offerts pour la signature d'un Pack
        Essentiel ou Premium Cogedim, hors honoraires de mise en location. La
        durée initiale de 6 mois est tacitement reconductible pour une durée de
        3 ans ferme, puis à l'issue de cette seconde période, le mandat est
        renouvelable annuellement par tacite reconduction. Le mandant aura la
        faculté de résilier le mandat pendant la période initiale d'une durée de
        6 mois avec un délai de prévenance de quinze jours.
      </p>
    </>
  );
}

function TailoredSlide2() {
  const [itemActive, setItemActive] = createSignal(1);

  return (
    <>
      <p class="catchline">
        Nos accords compétitifs auprès des meilleures banques vous permettent de
        bénéficier de l’offre de financement la plus appropriée pour votre
        achat. Découvrez les avantages de notre structure interne de courtage
        Altarea Solution Financement !
      </p>
      <div class="services-slider">
        <div
          class="inner-slider"
          classList={{
            "step-1": itemActive() === 1,
            "step-2": itemActive() === 2,
            "step-3": itemActive() === 3,
          }}
          data-test="cards"
        >
          <div class="program-service">
            <div
              class="service"
              classList={{ active: itemActive() === 1 }}
              data-test="card"
            >
              <h3>Le conseil</h3>
              <span class="icon-service">
                <IconService05 />
              </span>
              <p>
                Un conseiller unique et dédié vous accompagne pour trouver la
                meilleure offre de financement.
              </p>
            </div>
          </div>
          <div class="program-service">
            <div
              class="service"
              classList={{ active: itemActive() === 2 }}
              data-test="card"
            >
              <h3>L'accompagnement</h3>
              <span class="icon-service">
                <IconService06 />
              </span>
              <p>
                Profitez d’un suivi personnalisé du montage du dossier à la
                signature notaire.
              </p>
            </div>
          </div>
          <div class="program-service">
            <div
              class="service"
              classList={{ active: itemActive() === 3 }}
              data-test="card"
            >
              <h3>Nos partenaires</h3>
              <span class="icon-service">
                <IconService07 />
              </span>
              <p>
                Bénéficiez des solutions de crédits les plus concurrentielles
                grâce à nos accords avec les meilleures banques.
              </p>
            </div>
          </div>
        </div>
        <i
          aria-hidden="true"
          class="cog-icon nav-arrow previous"
          onClick={() => {
            if (itemActive() > 1) {
              setItemActive(itemActive() - 1);
            }
          }}
          classList={{ disabled: itemActive() === 1 }}
          data-test="btn-previous"
        >
          <IconKeyboardArrowLeft />
        </i>
        <i
          aria-hidden="true"
          class="cog-icon nav-arrow next"
          onClick={() => {
            if (itemActive() < 3) {
              setItemActive(itemActive() + 1);
            }
          }}
          classList={{ disabled: itemActive() === 3 }}
          data-test="btn-next"
        >
          <IconKeyboardArrowRight />
        </i>
      </div>
      <a
        href={urlRs(
          "journeys",
          "/cogedim-pour-vous/la-difference-cogedim/financement-sur-mesure.html",
        )}
        class="btn"
        target="_blank"
        data-test="btn-link"
      >
        En savoir plus
      </a>
    </>
  );
}

function TailoredSlide3() {
  const [itemActive, setItemActive] = createSignal(1);

  return (
    <>
      <p class="catchline">
        Spécialiste de la gestion locative depuis plus de 20 ans, notre
        structure interne Altarea Gestion Immobilière vous assure suivi, conseil
        et assistance pour vous permettre d’investir en toute sérénité.
      </p>
      <div class="services-slider">
        <div
          class="inner-slider"
          classList={{
            "step-1": itemActive() === 1,
            "step-2": itemActive() === 2,
            "step-3": itemActive() === 3,
          }}
          data-test="cards"
        >
          <div class="program-service">
            <div
              class="service"
              classList={{ active: itemActive() === 1 }}
              data-test="card"
            >
              <h3>Garanties</h3>
              <span class="icon-service">
                <IconService08 />
              </span>
              <ul>
                <li>
                  <i aria-hidden="true" class="cog-icon">
                    <IconCheck />
                  </i>
                  Loyers impayés
                </li>
                <li>
                  <i aria-hidden="true" class="cog-icon">
                    <IconCheck />
                  </i>
                  Vacance locative
                </li>
                <li>
                  <i aria-hidden="true" class="cog-icon">
                    <IconCheck />
                  </i>
                  Assurance propriétaire non occupant
                </li>
              </ul>
            </div>
          </div>
          <div class="program-service">
            <div
              class="service"
              classList={{ active: itemActive() === 2 }}
              data-test="card"
            >
              <h3>Transparence</h3>
              <span class="icon-service">
                <IconService09 />
              </span>
              <p>
                Recevez un compte-rendu de gérance détaillé tous les mois
                (loyers, honoraires, assurances etc.).
              </p>
            </div>
          </div>
          <div class="program-service">
            <div
              class="service"
              classList={{ active: itemActive() === 3 }}
              data-test="card"
            >
              <h3>Suivi</h3>
              <span class="icon-service">
                <IconService10 />
              </span>
              <p>
                Votre gestionnaire dédié assure toutes les étapes de la location
                dans un souci d’optimisation de votre patrimoine.
              </p>
            </div>
          </div>
        </div>
        <i
          aria-hidden="true"
          class="cog-icon nav-arrow previous"
          onClick={() => {
            if (itemActive() > 1) {
              setItemActive(itemActive() - 1);
            }
          }}
          classList={{ disabled: itemActive() === 1 }}
          data-test="btn-previous"
        >
          <IconKeyboardArrowLeft />
        </i>
        <i
          aria-hidden="true"
          class="cog-icon nav-arrow next"
          onClick={() => {
            if (itemActive() < 3) {
              setItemActive(itemActive() + 1);
            }
          }}
          classList={{ disabled: itemActive() === 3 }}
          data-test="btn-next"
        >
          <IconKeyboardArrowRight />
        </i>
      </div>
      <a
        href={urlRs(
          "journeys",
          "/cogedim-pour-vous/la-difference-cogedim/la-gestion-locative-cle-main/",
        )}
        class="btn"
        target="_blank"
        data-test="btn-link"
      >
        En savoir plus
      </a>
    </>
  );
}
