import { createMemo, For, Match, Switch } from "solid-js";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { urlRs } from "~/utils/url";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import IconComplimentaryHouse from "~/img/complimentary/house.svg";
import IconComplimentaryLiving from "~/img/complimentary/living.svg";
import IconComplimentaryMoney from "~/img/complimentary/money.svg";
import IconComplimentaryPig from "~/img/complimentary/pig.svg";

import "./ComplimentaryContents.css";

type ComplimentaryContentsProps = {
  wrapper: ProgramWrapper;
};

type ComplimentaryBoxProps = {
  icon: string;
  title: string;
  system: string;
  description: string;
  url: string;
};

export default function ComplimentaryContents(
  props: ComplimentaryContentsProps,
) {
  const settingsContext = useDrupalSettingsContext();

  const contentsList = createMemo(() => {
    const contents: ComplimentaryBoxProps[] = [];

    const vatIncContent: ComplimentaryBoxProps = {
      icon: "house",
      title: "Bénéficiez du prêt à taux zéro",
      system: "ptz",
      description:
        "Jusqu’à 40% de votre résidence principale financés sans intérêt, dans le cadre d’une primo-accession.",
      url: urlRs(
        "journeys",
        "/le-neuf-decrypte/des-aides-pour-devenir-proprietaire/le-pret-a-taux-zero.html",
      ),
    };
    if (props.wrapper.program.field_disp_grid_vat_inc) {
      contents.push(vatIncContent);
    }

    const vatIncReducedContent: ComplimentaryBoxProps = {
      icon: "money",
      title: "Bénéficiez de la TVA réduite",
      system: "vat-inc-reduced",
      description:
        "TVA à 5,5% ou 7% pour votre projet immobilier en zone ANRU et sous conditions de ressources. Cumulable au Prêt à Taux Zéro&nbsp;!",
      url: urlRs(
        "journeys",
        "/le-neuf-decrypte/des-aides-pour-devenir-proprietaire/une-tva-reduite-a-5-5.html",
      ),
    };
    if (props.wrapper.program.field_disp_grid_vat_inc_reduced) {
      contents.push(vatIncReducedContent);
    }

    const vatIncMasteredContent: ComplimentaryBoxProps = {
      icon: "money",
      title: "Bénéficiez des prix maîtrisés",
      system: "vat-inc-mastered",
      description:
        "Tous les avantages du neuf avec des conditions de financement avantageuses pour les primo-accédants.",
      url: urlRs(
        "journeys",
        "/le-neuf-decrypte/des-aides-pour-devenir-proprietaire/l-accession-a-prix-maitrise.html",
      ),
    };
    if (props.wrapper.program.field_disp_grid_vat_inc_mastered) {
      contents.push(vatIncMasteredContent);
    }

    const lmnpVatExContent: ComplimentaryBoxProps = {
      icon: "pig",
      title: "Défiscalisez en statut LMNP géré",
      system: "lmnp-vat-ex",
      description:
        "Cumulez favorablement la récupération immédiate de la TVA et la défiscalisation de vos revenus locatifs à long terme.",
      url: urlRs(
        "journeys",
        "/le-neuf-decrypte/des-solutions-pour-investir/le-statut-lmp-lmnp.html",
      ),
    };
    if (props.wrapper.program.field_disp_grid_lmnp_vat_ex) {
      contents.push(lmnpVatExContent);
    }

    const lmnpVatIncContent: ComplimentaryBoxProps = {
      icon: "pig",
      title: "Investissez en LMNP",
      system: "lmnp-vat-inc",
      description:
        "Bénéficiez d’une fiscalité allégée en louant meublé pour construire votre patrimoine.",
      url: urlRs(
        "journeys",
        "/investir/les-solutions-pour-investir-dans-le-neuf/le-dispositif-lmnp/",
      ),
    };
    if (props.wrapper.program.field_disp_grid_lmnp_vat_inc) {
      contents.push(lmnpVatIncContent);
    }

    const bareOwnershipContent: ComplimentaryBoxProps = {
      icon: "pig",
      title: "Investissez en Nue-Propriété",
      system: "bare-ownership",
      description:
        "Un bien à prix réduit, défiscalisé, sans contrainte d’entretien et de gestion locative.",
      url: urlRs(
        "journeys",
        "/le-neuf-decrypte/des-solutions-pour-investir/l%E2%80%99achat-en-nue-propriete.html",
      ),
    };
    if (props.wrapper.program.field_disp_grid_bare_ownership) {
      contents.push(bareOwnershipContent);
    }

    const guaranteesContent: ComplimentaryBoxProps = {
      icon: "living",
      title: "Nos services pour vous accompagner",
      system: "services",
      description:
        "Financement sur mesure, Parrainage,... Profitez de tous nos services.",
      url: urlRs("journeys", "/cogedim-pour-vous/la-difference-cogedim/"),
    };
    contents.push(guaranteesContent);

    const reasonsContent: ComplimentaryBoxProps = {
      icon: "house",
      title: "Au service de nos clients",
      system: "customers",
      description:
        "Suivi individuel de A à Z, espace client en ligne,... Bénéficiez d’experts à vos côtés tout au long de votre projet chez Cogedim.",
      url: urlRs(
        "journeys",
        "/cogedim-pour-vous/la-difference-cogedim/un-accompagnement-sur-mesure.html",
      ),
    };
    contents.push(reasonsContent);

    const assetsContent: ComplimentaryBoxProps = {
      icon: "house",
      title: "Les atouts du neuf",
      system: "dwell",
      description:
        "Réalisez un achat serein en choisissant le neuf : garanties, frais de notaire réduits, économies d’énergie...",
      url: urlRs(
        "journeys",
        "/le-neuf-decrypte/les-atouts-du-neuf/un-eventail-de-garanties.html",
      ),
    };
    contents.push(assetsContent);

    if (
      props.wrapper.program.field_program_type?.drupal_internal__tid ===
      settingsContext.program_types.terrains
    ) {
      while (contents.length > 0) {
        contents.pop();
      }

      contents.push(vatIncContent);
      contents.push(guaranteesContent);
      contents.push(vatIncReducedContent);
    }

    return contents;
  });

  return (
    <>
      <section class="complimentary-contents" data-test="block-contents">
        <div class="content-part">
          <div class="row">
            <For each={contentsList().slice(0, 3)}>
              {(content) => (
                <ComplimentaryBox
                  icon={content.icon}
                  title={content.title}
                  system={content.system}
                  description={content.description}
                  url={content.url}
                />
              )}
            </For>
          </div>
        </div>
      </section>
    </>
  );
}

function ComplimentaryBox(props: ComplimentaryBoxProps) {
  return (
    <>
      <div class="box" data-test={`content-${props.system}`}>
        <span class="icon">
          <Switch>
            <Match when={props.icon === "house"}>
              <IconComplimentaryHouse />
            </Match>
            <Match when={props.icon === "living"}>
              <IconComplimentaryLiving />
            </Match>
            <Match when={props.icon === "money"}>
              <IconComplimentaryMoney />
            </Match>
            <Match when={props.icon === "pig"}>
              <IconComplimentaryPig />
            </Match>
          </Switch>
        </span>
        <p class="sub-title">
          <a href={props.url} title={`Aller vers ${props.title}`}>
            {props.title}
          </a>
        </p>
        <p class="description" innerHTML={props.description} />
        <p class="link">
          <a href={props.url}>En savoir plus</a>
        </p>
      </div>
    </>
  );
}
