import {
  createEffect,
  createMemo,
  createRenderEffect,
  DEV,
  lazy,
  Show,
  Suspense,
} from "solid-js";
import FirstScreen from "~/components/Program/Components/FirstScreen";
import StackBar from "~/components/Program/Components/StackBar";
import Presentation from "~/components/Program/Components/Presentation";
import CommercialOffer from "~/components/shared/CommercialOffer";
import Grid from "~/components/Program/Components/Grid";
import SalesState from "~/components/Program/Components/SalesState";
import * as helper from "~/utils/helper_program";
import Block3D from "~/components/Program/Components/Block3D";
import Cogehome3D from "~/components/Program/Components/Cogehome3D";
import CityscanBlock from "~/components/Program/Components/CityscanBlock";
import { hasNoEscape } from "~/utils/no_escape";
import InvestBlock from "~/components/Program/Components/InvestBlock";
import SalesOffice from "~/components/Program/Components/SalesOffice";
import Tailored from "~/components/Program/Components/Tailored";
import ProgramsNearby from "~/components/Program/Components/ProgramsNearby";
import ComplimentaryContents from "~/components/Program/Components/ComplimentaryContents";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import Breadcrumb from "~/components/shared/Breadcrumb";
import { urlRs } from "~/utils/url";
import OtherCities from "~/components/Program/Components/OtherCities";
import {
  deliveryTrimester,
  geographyBreadcrumbItems,
  getGrid,
  hasLMNPVatExSlice,
  shouldShowInPageForm,
} from "~/utils/helper_program";
import { useLotActive } from "~/contexts/LotActiveContext";
import GridPromo from "~/components/Program/Components/GridPromo";
import ProgramFormInPage from "~/components/Program/Components/ProgramFormInPage";

import "./ProgramCommon.css";

import type { ProgramWrapper } from "~/utils/program_wrapper";
import type { BreadcrumbItem } from "~/types/breadcrumb";
import ProgramAgency from "~/components/Program/Components/ProgramAgency";
import { useEventsContext } from "~/contexts/EventsContext";
import GtmVars from "../shared/Trackers/GtmVars";
import { brandClassName, stripTags } from "~/utils/format";
import PromosSegmentsMkg from "~/components/shared/PromosSegmentsMkg";
import { Portal } from "solid-js/web";

const LotDrawer = lazy(() => import("./Components/LotDrawer"));

type ProgramProps = {
  wrapper: ProgramWrapper;
};

export function ProgramStd(props: ProgramProps) {
  const settingsContext = useDrupalSettingsContext();

  const breadcrumbItems = createMemo(() => {
    const items: BreadcrumbItem[] = [];

    const root: BreadcrumbItem = {
      href: urlRs("search", "/programme-immobilier-neuf/"),
      text: "Programmes immobiliers neufs",
    };
    items.push(root);

    const geographiesTemp: BreadcrumbItem[] = [];
    const geographies: BreadcrumbItem[] = geographyBreadcrumbItems(
      props.wrapper.program.field_geography,
      geographiesTemp,
    ).toReversed();

    geographies.forEach((geography: BreadcrumbItem) => {
      items.push(geography);
    });

    const lastBreadCrumbItem: BreadcrumbItem = {
      text: props.wrapper.program?.title,
    };
    items.push(lastBreadCrumbItem);

    return items;
  });

  const [lotProvider] = useLotActive();

  if (DEV) {
    createEffect(() => {
      if (lotProvider.lot) {
        console.log("Lot current ID:", lotProvider.lot.id);
      }
    });
  }

  createRenderEffect(() => {
    const [, { setTemplate }] = useEventsContext();
    // By default, template is set to "program-std"
    if (helper.isPreview(props.wrapper)) {
      setTemplate("program-preview");
    }
  });

  // Get program URL from meta tag
  const programUrl = () =>
    props.wrapper.program.metatag.find(
      (tag) => tag.attributes.rel === "canonical",
    )?.attributes.href;

  const photoUrl = () => {
    if (props.wrapper.program.field_image_desktop?.length) {
      const domain = programUrl()?.split("/")[2];

      return `https://${domain}${props.wrapper.program.field_image_desktop!.at(0)!.uri.url}`;
    }
    return "";
  };

  const lotsIds = createMemo(() => {
    const grid = getGrid(props.wrapper);
    if (grid.length) {
      return grid.flatMap((typo) =>
        typo.lots.map(
          (lot) => `${props.wrapper.program.field_program_id}-${lot.number}`,
        ),
      );
    }

    return [];
  });

  const isIDF = () => breadcrumbItems()[1]?.text === "Île-de-France";
  const tpl = () => {
    if (!props.wrapper.program.field_geography) {
      return "program-missing-geography";
    }

    if (helper.isPreview(props.wrapper)) {
      if (isIDF()) {
        return "program-preview-idf";
      }
      return "program-preview-other";
    }

    if (helper.isOutOfStock(props.wrapper)) {
      if (isIDF()) {
        return "program-oos-idf";
      }
      return "program-oos-other";
    }

    if (isIDF()) {
      return "program-std-idf";
    }
    return "program-std-other";
  };

  const gtmValues = () => {
    return {
      "all.pageType": "product page",
      "all.mainCategory": breadcrumbItems()[0].text,
      "all.category": breadcrumbItems()[1]?.text ?? "",
      "all.subCategory": breadcrumbItems()[2]?.text ?? "",
      "all.subsubCategory": breadcrumbItems()[3]?.text ?? "",
      "all.subsubsubCategory": breadcrumbItems()[4]?.text ?? "",
      "produit.name": props.wrapper.program.title,
      "produit.id": props.wrapper.program.drupal_internal__nid + "",
      "produit.id_pp": props.wrapper.program.field_program_id ?? "",
      "produit.url": programUrl() ?? "",
      "produit.codePostal": props.wrapper.program.field_postal_code ?? "",
      "produit.region": breadcrumbItems()[1]?.text ?? "",
      "produit.departement": breadcrumbItems()[2]?.text ?? "",
      "produit.ville": breadcrumbItems()[3]?.text ?? "",
      "produit.adresse": `${props.wrapper.program.field_so_street} ${props.wrapper.program.field_so_postal_code} ${props.wrapper.program.field_so_city}`,
      "produit.livraison": stripTags(deliveryTrimester(props.wrapper)!),
      "produit.photo": photoUrl(),
      "produit.type": props.wrapper.program.field_sales_state?.name ?? "",
      idLot: lotsIds(),
      template: tpl(),
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <article
        class={`node-program ${brandClassName(props.wrapper.program.field_brand.field_id)}`}
      >
        <Show when={lotProvider.wrapper && lotProvider.lot} keyed>
          <Suspense>
            <LotDrawer wrapper={lotProvider.wrapper!} lot={lotProvider.lot!} />
          </Suspense>
        </Show>
        <FirstScreen wrapper={props.wrapper} />
        <StackBar wrapper={props.wrapper} />
        <div class="program-presentation-container">
          <div class="content-part">
            <Presentation wrapper={props.wrapper} />
          </div>
        </div>
        <div class="program-sections">
          <div class="content-part">
            <Show
              when={
                props.wrapper.program.field_promos_segments_mkg &&
                props.wrapper.program.field_promos_segments_mkg.length > 0
              }
            >
              <PromosSegmentsMkg
                promosSegmentsMkg={
                  props.wrapper.program.field_promos_segments_mkg!
                }
              />
            </Show>

            <GridPromo wrapper={props.wrapper} />

            <section id="prix-plans">
              <Show when={props.wrapper.program?.animation}>
                <CommercialOffer animation={props.wrapper.program.animation!} />
              </Show>
              <Grid wrapper={props.wrapper} />
            </section>

            <Show when={shouldShowInPageForm(props.wrapper)}>
              <ProgramFormInPage wrapper={props.wrapper} />
            </Show>

            <Show
              when={
                props.wrapper.program.field_program_type
                  ?.drupal_internal__tid !==
                settingsContext.program_types.terrains
              }
            >
              <SalesState wrapper={props.wrapper} />
            </Show>

            <Show
              when={
                helper.virtualVisitsLength(props.wrapper) > 0 &&
                !props.wrapper.program.field_is_cogehome3d
              }
            >
              <Block3D wrapper={props.wrapper} />
            </Show>
            <Show
              when={
                helper.virtualVisitsLength(props.wrapper) > 0 &&
                props.wrapper.program.field_is_cogehome3d
              }
            >
              <Cogehome3D
                wrapper={props.wrapper}
                brandId={props.wrapper.program.field_brand.field_id}
              />
            </Show>

            <Show
              when={
                !props.wrapper.program.field_address_hide &&
                settingsContext.cityscan_is_enabled
              }
            >
              <CityscanBlock
                wrapper={props.wrapper}
                brandId={props.wrapper.program.field_brand.field_id}
              />
            </Show>

            <Show
              when={
                !helper.isPreview(props.wrapper) &&
                (props.wrapper.program.field_disp_grid_lmnp_vat_ex ||
                  props.wrapper.program.field_disp_grid_lmnp_vat_inc) &&
                props.wrapper.program.field_program_type
                  ?.drupal_internal__tid !==
                  settingsContext.program_types.terrains
              }
            >
              <InvestBlock wrapper={props.wrapper} />
            </Show>

            <SalesOffice wrapper={props.wrapper} />
          </div>
        </div>
        <Tailored
          onlyFinance={hasLMNPVatExSlice(props.wrapper)}
          brandId={props.wrapper.program.field_brand.field_id}
        />
        <Show when={!hasNoEscape()}>
          <Show when={props.wrapper.program.other_programs_nearby.length}>
            <ProgramsNearby wrapper={props.wrapper} />
          </Show>

          <Show when={props.wrapper.program.other_cities.length > 0}>
            <OtherCities
              cities={props.wrapper.program.other_cities}
              displayAlone={
                props.wrapper.program.other_programs_nearby.length === 0
              }
            />
          </Show>
        </Show>

        <Show when={props.wrapper.program.field_brand.field_id === "0"}>
          <ProgramAgency />
        </Show>

        <Show when={!hasNoEscape()}>
          <ComplimentaryContents wrapper={props.wrapper} />
        </Show>

        <Portal mount={document.getElementById("legals-program-receiver")!}>
          <Show when={props.wrapper.program.field_legals}>
            <p
              class="legals"
              data-test="legals-program"
              innerHTML={props.wrapper.program.field_legals}
            />
          </Show>
        </Portal>
      </article>
      <Breadcrumb items={breadcrumbItems()} />
    </>
  );
}
