import "./ProgramAgency.css";

export default function ProgramAgency() {
  return (
    <>
      <div class="program-agency" data-test="agency">
        <div class="block-agency">
          <h2>
            Des milliers <strong>de clients satisfaits</strong>
          </h2>
          <div class="reviews">
            <div id="23490dae-3dbd-427e-9de3-6a588dc7a2a2" />
            <script
              async
              charset="utf-8"
              src="https://widgets.rr.skeepers.io/generated/bf5c3d00-bd34-3784-356b-65e9f019e959/23490dae-3dbd-427e-9de3-6a588dc7a2a2.js"
            />
          </div>
        </div>
      </div>
    </>
  );
}
