import { createMemo, Show } from "solid-js";
import { getRegulations, isPreview } from "~/utils/helper_program";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { getRegulation } from "~/utils/helper_settings";

import type { Settings } from "~/types/common";
import type { ProgramWrapper } from "~/utils/program_wrapper";

import "./GridPromo.css";

export default function GridPromo(props: { wrapper: ProgramWrapper }) {
  const settings = useDrupalSettingsContext();

  return (
    <>
      <Show when={props.wrapper.program.field_disp_grid_vat_inc}>
        <section class="grids promo">
          <Show
            when={isPreview(props.wrapper)}
            fallback={
              <GridPromoVatInc settings={settings} wrapper={props.wrapper} />
            }
          >
            <GridPromoVatIncPreview settings={settings} />
          </Show>
        </section>
      </Show>
      <Show when={props.wrapper.program.field_disp_grid_vat_inc_reduced}>
        <section class="grids promo">
          <Show
            when={isPreview(props.wrapper)}
            fallback={
              <GridPromoVatIncReduced
                settings={settings}
                wrapper={props.wrapper}
              />
            }
          >
            <GridPromoVatIncReducedPreview settings={settings} />
          </Show>
        </section>
      </Show>
      <Show when={props.wrapper.program.field_disp_grid_vat_inc_mastered}>
        <section class="grids promo">
          <Show
            when={isPreview(props.wrapper)}
            fallback={
              <GridPromoVatIncMastered
                settings={settings}
                wrapper={props.wrapper}
              />
            }
          >
            <GridPromoVatIncMasteredPreview settings={settings} />
          </Show>
        </section>
      </Show>
      <Show when={props.wrapper.program.field_disp_grid_vat_inc_brs}>
        <section class="grids promo">
          <Show
            when={isPreview(props.wrapper)}
            fallback={
              <GridPromoVatIncBRS settings={settings} wrapper={props.wrapper} />
            }
          >
            <GridPromoVatIncBRSPreview settings={settings} />
          </Show>
        </section>
      </Show>
      <Show when={props.wrapper.program.field_disp_grid_bare_ownership}>
        <section class="grids promo">
          <Show
            when={isPreview(props.wrapper)}
            fallback={
              <GridPromoBareOwnership
                settings={settings}
                wrapper={props.wrapper}
              />
            }
          >
            <GridPromoBareOwnershipPreview settings={settings} />
          </Show>
        </section>
      </Show>
      <Show when={props.wrapper.program.field_disp_grid_lmnp_vat_inc}>
        <section class="grids promo">
          <Show
            when={isPreview(props.wrapper)}
            fallback={
              <GridPromoLMNPVatInc
                settings={settings}
                wrapper={props.wrapper}
              />
            }
          >
            <GridPromoLMNPVatIncPreview settings={settings} />
          </Show>
        </section>
      </Show>
      <Show when={props.wrapper.program.field_disp_grid_lmnp_vat_ex}>
        <section class="grids promo">
          <Show
            when={isPreview(props.wrapper)}
            fallback={
              <GridPromoLMNPVatEx settings={settings} wrapper={props.wrapper} />
            }
          >
            <GridPromoLMNPVatExPreview settings={settings} />
          </Show>
        </section>
      </Show>
      <Show when={props.wrapper.program.field_disp_grid_patrimonial}>
        <section class="grids promo">
          <Show
            when={isPreview(props.wrapper)}
            fallback={
              <GridPromoPatrimonial
                settings={settings}
                wrapper={props.wrapper}
              />
            }
          >
            <GridPromoPatrimonialPreview settings={settings} />
          </Show>
        </section>
      </Show>
    </>
  );
}

function GridPromoVatInc(props: {
  settings: Settings;
  wrapper: ProgramWrapper;
}) {
  const textMain = createMemo(() => {
    return getRegulations(props.wrapper).find(
      (obj) =>
        obj.drupal_internal__tid ===
        props.settings.regulations_tids.tva_normale,
    )?.field_text_main?.value;
  });
  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-vat-inc"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}

function GridPromoVatIncPreview(props: { settings: Settings }) {
  const textMain = createMemo(() => {
    return getRegulation(
      props.settings,
      props.settings.regulations_tids.tva_normale,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-vat-inc"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}

function GridPromoVatIncReduced(props: {
  settings: Settings;
  wrapper: ProgramWrapper;
}) {
  const textMain = createMemo(() => {
    return getRegulations(props.wrapper).find(
      (obj) =>
        obj.drupal_internal__tid ===
        props.settings.regulations_tids.tva_reduite,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-vat-inc-reduced"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}

function GridPromoVatIncReducedPreview(props: { settings: Settings }) {
  const textMain = createMemo(() => {
    return getRegulation(
      props.settings,
      props.settings.regulations_tids.tva_reduite,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-vat-inc-reduced"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}

function GridPromoVatIncMastered(props: {
  settings: Settings;
  wrapper: ProgramWrapper;
}) {
  const textMain = createMemo(() => {
    return getRegulations(props.wrapper).find(
      (obj) =>
        obj.drupal_internal__tid ===
        props.settings.regulations_tids.prix_maitrises,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-vat-inc-mastered"
          innerHTML={textMain()}
        />
        {/* Backup
        <div class="heading">
          <img src="/images/icone-brs.svg" alt="Résidence principale" />
          <div>
            <h3>Vous achetez votre résidence principale&nbsp;?</h3>
            <p>
              Vous pourrez bientôt découvrir nos logements en prix maîtrisés
            </p>
          </div>
        </div>
        <div class="more-infos" id="grid-promo-vatincmastered">
          <div class="expandable">
            <p>
              Fruit d’un partenariat entre les promoteurs immobiliers et les
              communes, ce dispositif vous permet d’acquérir, sous certaines
              conditions, un logement neuf à un prix réduit par rapport au
              marché immobilier local. En moyenne, vous pourrez profiter d’une
              économie entre 20 et 30% sur le prix d’acquisition de votre
              logement neuf.
            </p>
            <p>
              Ce sont les <strong>collectivités</strong> qui
              <strong>
                fixent les règles d’accession à la propriété à prix maîtrisé.
                Elles varient donc d’une ville à l’autre.
              </strong>
              Vous devez, entre autres, avoir des revenus inférieurs à un
              plafond de ressources basé sur le barème PSLA, PAS ou PLI, et
              occuper le logement à titre de résidence principale.
            </p>
            <p>
              Vous vous engagez, par ailleurs, à conserver votre logement
              plusieurs années. En cas d’une revente anticipée, le prix peut
              être encadré par les clauses anti spéculatives de votre commune.
            </p>
            <p>
              <strong>À retenir</strong>
            </p>
            <p>Grâce à ce dispositif, vous pourrez&nbsp;:</p>
            <ul>
              <li>
                Acquérir un logement neuf de qualité, à prix réduit&nbsp;;
              </li>
              <li>
                Accéder à un bien immobilier plus grand ou avec un meilleur
                emplacement, avec un budget inchangé&nbsp;;
              </li>
              <li>
                Vous constituer un patrimoine, en bénéficiant d’autres
                dispositifs d’aides au financement immobilier mis en place par
                le gouvernement et les collectivités locales (Prêt à taux zéro,
                Prêt Action Logement, Prêt à l’Accession Sociale…)&nbsp;;
              </li>
              <li>
                Profiter d’une réduction des frais de notaire grâce à l’achat
                dans l’immobilier neuf.
              </li>
            </ul>
            <div class="button">
              <a
                href="/habiter/les-aides-pour-acheter-dans-le-neuf/l-accession-a-prix-maitrise.html"
                class="btn"
              >
                En savoir plus
              </a>
            </div>
          </div>
          <div class="more-button">
            <i
              aria-hidden="true"
              class="cog-icon"
              onclick="document.getElementById('grid-promo-vatincmastered').classList.toggle('active');"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="m12 15.375-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4Z"></path></svg>
            </i>
          </div>
        </div>
        */}
      </Show>
    </>
  );
}

function GridPromoVatIncMasteredPreview(props: { settings: Settings }) {
  const textMain = createMemo(() => {
    return getRegulation(
      props.settings,
      props.settings.regulations_tids.prix_maitrises,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-vat-inc-mastered"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}

function GridPromoVatIncBRS(props: {
  settings: Settings;
  wrapper: ProgramWrapper;
}) {
  const textMain = createMemo(() => {
    return getRegulations(props.wrapper).find(
      (obj) => obj.drupal_internal__tid === props.settings.regulations_tids.brs,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-vat-inc-brs"
          innerHTML={textMain()}
        />
        {/* Backup
        <div class="heading">
          <img src="/images/icone-brs.svg" alt="Résidence principale" />
          <div>
            <h3>Vous achetez votre résidence principale&nbsp;?</h3>
            <p>
              Vous pourrez bientôt découvrir nos logements en prix BRS,
              accessibles sous conditions de ressources
            </p>
          </div>
        </div>
        <div class="more-infos" id="grid-promo-vatincbrs">
          <div class="expandable">
            <p>
              Le bail réel solidaire (BRS) est un dispositif d’accès social à la
              propriété de sa résidence principale, à prix abordable et sous
              conditions de ressources, correspondant à ceux du prêt social à
              l’accession (PSLA).
            </p>
            <p>
              Le BRS repose sur la dissociation du foncier (le terrain) et du
              bâti (le logement). Vous pouvez ainsi devenir propriétaire de
              votre logement à un prix pouvant aller jusqu’à 40% de décote du
              prix du marché.
            </p>
            <p>
              En contrepartie vous signez un bail réel solidaire et vous devrez
              vous acquitter d’une redevance mensuelle auprès d’un organisme
              foncier solidaire (OFS) qui achète le terrain.
            </p>
            <p>
              Vous êtes ainsi propriétaire de votre logement mais locataire du
              terrain sur lequel il se trouve.
            </p>
            <p>
              Les OFS sont des organismes à but non lucratif, agréés par le
              préfet de région, ayant pour objet de détenir des terrains sur le
              très long terme.
            </p>
            <p>
              Vous pouvez revendre votre logement à tout moment mais cela reste
              cependant encadré par l’OFS.
            </p>
            <p>
              <strong>À retenir</strong>
            </p>
            <ul>
              <li>
                Vous devenez propriétaire à un prix d’acquisition réduit de
                votre logement&nbsp;;
              </li>
              <li>
                Vous versez une redevance mensuelle correspondant à la location
                du terrain sur lequel il se trouve&nbsp;;
              </li>
              <li>
                Vous bénéficiez d’une TVA réduite à 5,5% pour un logement
                neuf&nbsp;;
              </li>
              <li>
                Vous profitez d’un abattement de 30% de la taxe foncière, selon
                la commune&nbsp;;
              </li>
              <li>
                Vous pouvez financer l’achat de votre logement avec un prêt à
                taux zéro (PTZ) au titre de votre 1ère acquisition.
              </li>
            </ul>
            <div class="button">
              <a
                href="/habiter/les-aides-pour-acheter-dans-le-neuf/le-bail-reel-solidaire.html"
                class="btn"
              >
                En savoir plus
              </a>
            </div>
          </div>
          <div class="more-button">
            <i
              aria-hidden="true"
              class="cog-icon"
              onclick="document.getElementById('grid-promo-vatincbrs').classList.toggle('active');"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="m12 15.375-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4Z"></path></svg>
            </i>
          </div>
        </div>
        */}
      </Show>
    </>
  );
}

function GridPromoVatIncBRSPreview(props: { settings: Settings }) {
  const textMain = createMemo(() => {
    return getRegulation(props.settings, props.settings.regulations_tids.brs)
      ?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-vat-inc-brs"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}

function GridPromoBareOwnership(props: {
  settings: Settings;
  wrapper: ProgramWrapper;
}) {
  const textMain = createMemo(() => {
    return getRegulations(props.wrapper).find(
      (obj) =>
        obj.drupal_internal__tid ===
        props.settings.regulations_tids.nue_propriete,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-bare-ownership"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}

function GridPromoBareOwnershipPreview(props: { settings: Settings }) {
  const textMain = createMemo(() => {
    return getRegulation(
      props.settings,
      props.settings.regulations_tids.nue_propriete,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-bare-ownership"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}

function GridPromoLMNPVatInc(props: {
  settings: Settings;
  wrapper: ProgramWrapper;
}) {
  const textMain = createMemo(() => {
    return getRegulations(props.wrapper).find(
      (obj) =>
        obj.drupal_internal__tid ===
        props.settings.regulations_tids.lmnp_vat_inc,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-lmnp-vat-inc"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}

function GridPromoLMNPVatIncPreview(props: { settings: Settings }) {
  const textMain = createMemo(() => {
    return getRegulation(
      props.settings,
      props.settings.regulations_tids.lmnp_vat_inc,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-lmnp-vat-inc"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}

function GridPromoLMNPVatEx(props: {
  settings: Settings;
  wrapper: ProgramWrapper;
}) {
  const textMain = createMemo(() => {
    return getRegulations(props.wrapper).find(
      (obj) =>
        obj.drupal_internal__tid ===
        props.settings.regulations_tids.lmnp_vat_ex,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-lmnp-vat-ex"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}

function GridPromoLMNPVatExPreview(props: { settings: Settings }) {
  const textMain = createMemo(() => {
    return getRegulation(
      props.settings,
      props.settings.regulations_tids.lmnp_vat_ex,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-lmnp-vat-ex"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}

function GridPromoPatrimonial(props: {
  settings: Settings;
  wrapper: ProgramWrapper;
}) {
  const textMain = createMemo(() => {
    return getRegulations(props.wrapper).find(
      (obj) =>
        obj.drupal_internal__tid ===
        props.settings.regulations_tids.patrimonial,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-patrimonial"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}

function GridPromoPatrimonialPreview(props: { settings: Settings }) {
  const textMain = createMemo(() => {
    return getRegulation(
      props.settings,
      props.settings.regulations_tids.patrimonial,
    )?.field_text_main?.value;
  });

  return (
    <>
      <Show when={textMain()}>
        <div
          class="promo-block"
          data-test="regulation-main-patrimonial"
          innerHTML={textMain()}
        />
      </Show>
    </>
  );
}
