import { Show } from "solid-js";
import { hasNoEscape } from "~/utils/no_escape";
import { urlRs } from "~/utils/url";
import {
  closingDelivery,
  closingDeliveryInThePast,
  getBestReturnRate,
} from "~/utils/helper_program";
import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import "./InvestBlock.css";

type InvestBlockProps = {
  wrapper: ProgramWrapper;
};

export default function InvestBlock(props: InvestBlockProps) {
  const [, { sendShowEvent }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const settingsContext = useDrupalSettingsContext();

  return (
    <>
      <section
        class="invest-block"
        data-ga-zone="bloc-invest"
        data-test="block-invest"
      >
        <h3>
          <strong>Nos solutions d’investissement</strong> sur cette résidence
        </h3>
        <div class="blocks" data-test="cards">
          <p data-test="chip" class="chips">
            <strong>98 %</strong>
            <span>
              des biens loués le 1<sup>er</sup> mois
            </span>
          </p>

          {/* LMNP GERE */}
          <Show when={props.wrapper.program.field_disp_grid_lmnp_vat_ex}>
            <div class="block" data-test="card-lmnp-vat-ex">
              <h4>Statut LMNP Géré</h4>
              <p>
                Bénéficiez du statut de loueur meublé non professionnel et
                profitez de revenus défiscalisés sur le long terme en louant
                meublé.
              </p>
              <ul class="no-style">
                <Show
                  when={
                    getBestReturnRate(props.wrapper, [
                      settingsContext.regulations_tids.lmnp_vat_ex,
                    ]) && props.wrapper.program.field_disp_return_rate
                  }
                >
                  <li>
                    Rentabilité LMNP géré jusqu’à&nbsp;:{" "}
                    {getBestReturnRate(props.wrapper, [
                      settingsContext.regulations_tids.lmnp_vat_ex,
                    ])}
                  </li>
                </Show>
                <Show
                  when={
                    !props.wrapper.program.field_date_delivery_hide &&
                    closingDelivery(props.wrapper)
                  }
                >
                  <Show
                    fallback={
                      <li
                        innerHTML={`Actabilité&nbsp;: ${closingDelivery(props.wrapper)}`}
                      />
                    }
                    when={closingDeliveryInThePast(props.wrapper)}
                  >
                    <li>Actabilité&nbsp;: immédiate</li>
                  </Show>
                </Show>
              </ul>
              <div class="flex-group">
                <h5>
                  Nos + avec
                  <img
                    src="/images/logo-altarea_solutions_services.svg"
                    alt="Altarea Solutions Services"
                    loading="lazy"
                    height="48"
                    width="48"
                  />
                </h5>
                <ul>
                  <li>Gestion locative complète</li>
                  <li>Garantie loyer impayé + logement vacant</li>
                  <li>
                    Appartement totalement équipé avec mobilier et cuisine, prêt
                    à la location
                  </li>
                  <li>Financement sur mesure</li>
                </ul>
              </div>
              <p class="more">
                <a
                  href={urlRs(
                    "journeys",
                    "/investir/les-solutions-pour-investir-dans-le-neuf/la-solution-meublee.html",
                  )}
                >
                  En savoir + sur la solution meublée
                </a>
              </p>
            </div>
          </Show>

          {/* LMNP */}
          <Show when={props.wrapper.program.field_disp_grid_lmnp_vat_inc}>
            <div class="block" data-test="card-lmnp-vat-inc">
              <h4>Statut LMNP</h4>
              <p>
                Bénéficiez du statut de loueur meublé non professionnel et
                profitez de revenus défiscalisés sur le long terme en louant
                meublé.
              </p>
              <ul class="no-style">
                <Show
                  when={
                    getBestReturnRate(props.wrapper, [
                      settingsContext.regulations_tids.lmnp_vat_inc,
                    ]) && props.wrapper.program.field_disp_return_rate
                  }
                >
                  <li>
                    Rentabilité marché jusqu’à&nbsp;:{" "}
                    {getBestReturnRate(props.wrapper, [
                      settingsContext.regulations_tids.lmnp_vat_inc,
                    ])}
                  </li>
                </Show>
                <Show
                  when={
                    !props.wrapper.program.field_date_delivery_hide &&
                    closingDelivery(props.wrapper)
                  }
                >
                  <Show
                    fallback={
                      <li
                        innerHTML={`Actabilité&nbsp;: ${closingDelivery(props.wrapper)}`}
                      />
                    }
                    when={closingDeliveryInThePast(props.wrapper)}
                  >
                    <li>Actabilité&nbsp;: immédiate</li>
                  </Show>
                </Show>
              </ul>
              <div class="flex-group">
                <h5>
                  Nos + avec
                  <img
                    src="/images/logo-altarea_solutions_services.svg"
                    alt="Altarea Solutions Services"
                    loading="lazy"
                    height="48"
                    width="48"
                  />
                </h5>
                <ul>
                  <li>Gestion locative complète</li>
                  <li>Garantie loyer impayé + logement vacant</li>
                  <li>
                    Appartement totalement équipé avec mobilier et cuisine, prêt
                    à la location
                  </li>
                  <li>Financement sur mesure</li>
                </ul>
              </div>
              <p class="more">
                <a
                  href={urlRs(
                    "journeys",
                    "/investir/les-solutions-pour-investir-dans-le-neuf/la-solution-meublee.html",
                  )}
                >
                  En savoir + sur la solution meublée
                </a>
              </p>
            </div>
          </Show>
        </div>
        <Show when={!hasNoEscape()} fallback={<div class="spacer" />}>
          <div class="study" data-test="financial-study">
            <p>
              Vous souhaitez connaître la fiscalité la mieux adaptée à votre
              projet ?
            </p>

            <button
              type="button"
              class="btn"
              data-test="cta-financial-study"
              onClick={(e) => {
                sendShowEvent(`financial-study`, e, {
                  nid: props.wrapper.program.drupal_internal__nid,
                });
                openModalForm!("financial-study", {
                  wrapper: props.wrapper,
                });
                setModalFormHeadline!("Étude personnalisée");
              }}
            >
              Demander votre étude personnalisée
            </button>
          </div>
        </Show>
      </section>
    </>
  );
}
